import { render, staticRenderFns } from "./HyUpLoadImg.vue?vue&type=template&id=6a2bcb84&scoped=true&"
import script from "./HyUpLoadImg.vue?vue&type=script&lang=js&"
export * from "./HyUpLoadImg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2bcb84",
  null
  
)

export default component.exports