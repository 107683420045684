<template>
	<!-- <div class=""> -->

	<!-- 修改弹窗 -->
	<el-dialog :close-on-click-modal="false" :title="$t('i18nn_ab505f0b8db48c6c')" append-to-body :visible.sync="dialogFormVisible"
		custom-class="myFullDialog4">
		<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 1000px;"
			v-loading="loading_load" :element-loading-text="$t('i18nn_1b4525c800280581')">

			<el-form-item :label="'权限仓库(参考)'" prop="">
				<el-alert :title="'当前账号有权限的仓库；若无权限的账号即使绑定，仓库绑定客户列表也不可见，但实际客户端客户可见'" type="warning">
				</el-alert>
				<whNoAllSelect ref="whNoSelect" :width="200"></whNoAllSelect>
			</el-form-item>

			<el-form-item :label="$t('i18nn_73768c3db7d6b661')" prop="" required>
				{{form.whNos.join(',')}}
			</el-form-item>

			<el-form-item :label="$t('hytxs0000060')" prop="" required>
				<!-- <el-button @click="openSelDialog()" type="warning" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button>
					<el-input type="text" disabled v-model="form.userName" style="width: 200px;"></el-input>
					<el-input type="text" disabled v-model="form.bckId" style="width: 200px;"></el-input> -->

				<!-- <span>{{ $t('hytxs0000060') }}</span> -->
				<cusSelFuzzyAll ref="cusSelFuzzy" :size="''" @changeData="changCus"></cusSelFuzzyAll>
			</el-form-item>




			<el-form-item :label="$t('Storage.tableColumn.remark')">
				<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit style="width: 300px;"></el-input>
			</el-form-item>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
		</div>
	</el-dialog>


	<!-- </div> -->
</template>
<script>
	import cusSelFuzzyAll from '@/components/WarehouseCenter2/components/cusSelFuzzyAll.vue';
	import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	export default {

		components: {
			cusSelFuzzyAll,
			whNoAllSelect
		},
		props: {
			openTime: {

			},
			row: {},
		},
		data() {
			return {
				dialogFormVisible: false,

				form: {
					userId: '', //
					// userName:'',
					whNos: [], //
					// whNoArr:[],
					remark: ''
				},
				formRules: {
					// amt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// remark: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
				},
				loading_load: false,
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime', newVal);
				this.dialogFormVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.resetForm('form');
				this.form.whNos = [this.row.whNo];
			},
			changCus(data) {
				console.log('changCus', data);
				this.form.userId = data.userId;
			},

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						// let formData = Object.assign({}, this.form);
						let formData = Object.assign({}, this.form);
						this.postData(this.$urlConfig.WhCusBindAdd, formData);
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}

				this.$nextTick(() => {
					try {
						this.$refs.cusSelFuzzy.init('');
					} catch (err) {
						console.log(err);
					}
				});
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				// this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					// this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;

						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
						this.$emit('success')
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					// this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>